import { AlertDisplay, createApp, FlatRoutes } from '@backstage/core';
import { ConfigSchemaPage } from '@backstage/plugin-config-schema';
import React from 'react';
import { Route } from 'react-router';
import { apis } from './apis/apis';
import * as plugins from './plugins';

const app = createApp({
  apis,
  plugins: Object.values(plugins),
});

const AppProvider = app.getProvider();
const AppRouter = app.getRouter();

const App = () => (
  React.createElement(AppProvider, null
    , React.createElement(AlertDisplay, null )
    , React.createElement(AppRouter, null
      , React.createElement(FlatRoutes, null
        , React.createElement(Route, { path: "/", element: React.createElement(ConfigSchemaPage, null ),} )
      )
    )
  )
);

export default App;
